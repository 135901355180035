<template>
  <div class="errPage-container">
    <img
      :src="errGif"
      class="img"
      width="324px"
      height="269px"
      alt="Girl has dropped her ice cream."
    />
    <h3 class="title">登录认证过期</h3>
    <p class="prompt-info">您可以点击下方按钮返回登录页面</p>
    <a-button class="go-back-btn" @click="goLogin">重新登录</a-button>
  </div>
</template>

<script>
import errGif from "@/assets/401_images/Group.png";

export default {
  name: "Page401",
  data() {
    return {
      errGif: errGif + "?" + +new Date(),
    };
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.go(-1);
      }
    },
    goLogin() {
      // this.$router.push("/login");
      const originPath = localStorage.getItem("originPath")
      localStorage.clear();
      sessionStorage.clear();
      // 为了邮件跳转
      if(originPath) localStorage.setItem("originPath", originPath)
      window.removeEventListener('beforeunload',window.remainStore);
      window.location.href = "/rms/login";
    },
  },
};
</script>

<style lang="less" scoped>
.errPage-container {
  width: 100%;
  height: 100%;
  background: #f7fafc;
  font-family: PingFang SC, Verdana, Genva, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3182ce;
  .img {
    width: 325px;
    height: 269px;
  }
  // 没权限 标题
  .title {
    font-size: 38px;
    line-height: 72px;
    font-weight: 600;
    color: #3182ce;
    padding: 40px 0 14px;
    margin: 0;
  }
  // 提示信息
  .prompt-info {
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #3182ce;
    margin: 0px 0 30px;
    padding: 0;
    // 返回链接
    .link-type {
      font-size: 30px;
      color: #7604f9f9;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: #0493f9f9;
      }
    }
  }
  // 重登按钮
  .go-back-btn {
    width: 13%;
    height: 60px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    font-size: 30px;
    text-indent: 7px;
    // background: #3182ce;
    color: #3182ce;
    border: 1px solid #3182ce;
    letter-spacing: 14px;
    border-radius: 5px;
    &:hover {
      background: rgba(4, 128, 244, 0.543);
      color: #fff;
    }
  }
}
</style>
